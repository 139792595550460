<template>
  <!-- Coming soon page-->
  <div v-if="ready" class="misc-wrapper">
    <b-link class="brand-logo bilge-logo" :to="{ path: '/' }">
      <b-img :src="brandLogo" alt="Logo" />
      <h2
        class="brand-text ml-1 mb-0 d-none d-md-block"
        :class="{
          'text-white': skin === 'dark',
          'text-primary': skin !== 'dark'
        }"
      >
        {{ brandTitle }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div v-if="transactionDetail.transaction_state === 1">
          <div class="d-flex justify-content-center align-items-center flex-column mb-1">
            <feather-icon size="120" icon="CheckIcon" class="text-success"></feather-icon>
            <h2>
              {{ $t('payment.payment_thanks.title') }}
            </h2>
          </div>
          <h4 class="mb-1">
            {{ $t('payment.payment_thanks.payment_no', { payment_no: $route.params.id }) }}
          </h4>
          <div class="d-flex justify-content-center align-items-baseline">
            <h1 class="mb-1 font-weight-bold">
              {{ $t('payment.payment_thanks.payment_amount') }}
              {{ showAmount() }}
            </h1>
            <h5 class="ml-1 text-secondary">
              {{ showInstallment() }}
            </h5>
          </div>
          <h4 class="mb-1">
            {{ $t('payment.payment_thanks.credit_card_no') }}
            {{ showCreditCard() }}
          </h4>
          <div class="mb-3">
            <span> {{ $t('payment.payment_thanks.message_1') }} </span>
            <b-link :to="{ name: '/Payment/TransactionDetail', params: { id: $route.params.id } }"> {{ $t('payment.payment_thanks.click_me_1') }}</b-link>
            <span> {{ $t('payment.payment_thanks.message_2') }} </span>
            <b-link :to="{ name: '/Payment/List' }"> {{ $t('payment.payment_thanks.click_me_2') }} </b-link>
            <span> {{ $t('payment.payment_thanks.message_3') }} </span>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center align-items-center flex-column mb-1">
            <feather-icon size="120" icon="XIcon" class="text-danger"></feather-icon>
            <h2>
              {{ $t('payment.payment_thanks.failed_title') }}
            </h2>
          </div>
          <h4 class="mb-1">
            {{ $t('payment.payment_thanks.payment_no', { payment_no: $route.params.id }) }}
          </h4>
          <div class="d-flex justify-content-center align-items-baseline">
            <h1 class="mb-1 font-weight-bold">
              {{ $t('payment.payment_thanks.payment_amount') }}
              {{ showAmount() }}
            </h1>
            <h5 class="ml-1 text-secondary">
              {{ showInstallment() }}
            </h5>
          </div>
          <h4 class="mb-1">
            {{ $t('payment.payment_thanks.credit_card_no') }}
            {{ showCreditCard() }}
          </h4>
          <p>{{ $t('payment.payment_thanks.failed_message') }}</p>
          <p>{{ $t('payment.payment_thanks.failed_message_title') }} {{ transactionDetail.detail_desc }}</p>
        </div>

        <b-button variant="primary" class="btn-md" @click="backHome()">
          {{ $t('BackToHome') }}
        </b-button>

        <b-img fluid src="@/assets/images/pages/payment_complete.svg" alt="Payment complete page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import { GET_TRANSACTION_DETAIL } from '@/store/services/payment-service'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  components: {
    BLink,
    BButton,
    BImg
  },
  data() {
    return {
      ready: false,
      transactionDetail: undefined
    }
  },
  computed: {
    ...mapGetters(['brandLogo', 'brandTitle']),
    skin() {
      return store.state.appConfig.layout.skin
    }
  },
  created() {
    this.$i18n.locale = localStorage.bilgeCodeLocale
    document.title = `${this.brandTitle} - ${this.$t(this.$route.meta.title)}`
    this.getData()
  },
  methods: {
    backHome() {
      this.$router.push({
        name: 'home'
      })
    },
    getData() {
      this.ready = false
      this.$store.dispatch(GET_TRANSACTION_DETAIL, this.$route.params.id).then(response => {
        this.ready = true
        this.transactionDetail = response
      })
    },
    showAmount() {
      return priceFormat(this.transactionDetail.total_amount, this.$i18n.locale, 'TRY')
    },
    showCreditCard() {
      let card = this.transactionDetail.credit_card_no
      return `${card.slice(0, 4)} ${card.slice(4, 8)} ${card.slice(8, 12)} ${card.slice(12, 16)}`
    },
    showInstallment() {
      let ins = this.transactionDetail.installment_count ?? 0
      let e_ins = this.transactionDetail.extra_installment_count ?? 0

      if (ins + e_ins > 0) {
        if (e_ins > 0) {
          return this.$t('payment.payment_thanks.extra_installment', { installment: ins, extra_installment: e_ins })
        }
        return this.$t('payment.payment_thanks.installment', { installment: ins })
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.bilge-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
  }
  h2 {
    font-weight: 600;
  }
}
</style>
